#main {
    display: grid;
    grid-template-columns: 30vw 70vw;
    background: linear-gradient(white, #ffa8a8, #ff8db7);
}

#home {
    background: linear-gradient(white, #ffa8a8, #ff8db7);
    width: 30vw;
    height: 100vh;
    position: relative;
    border-right: 1px solid gray;
}

#chat {
    background: linear-gradient(white, #ffa8a8, #ff8db7);
    width: 70vw;
    height: 100vh;
    position: relative;
    background: url(/public/cat-bg.png) no-repeat center center;
    background-size: 60vh;
}

.click {
    display: none;
}

@media only screen and (max-width: 500px) {
    #home {
        width: 100vw;
    }
    #chat {
        width: 100vw;
    }
    .click {
        display: block;
    }
}